import PropTypes from "prop-types"
import React from "react"

const HiddenVideo = ({ youtubeId }) => (
    <div>
        <iframe title="hidden video with a song" width="1" height="1" src={youtubeId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>{youtubeId}</iframe>
    </div>
)

HiddenVideo.propTypes = {
    youtubeId: PropTypes.string,
}

HiddenVideo.defaultProps = {
    youtubeId: ``,
}

export default HiddenVideo
